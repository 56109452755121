import { useToggle } from '@vueuse/core'
import type { OrderItem } from '~/types/OrderItem'

const [open, toggleOpen] = useToggle(false)

const orderItems = useLocalStorage<OrderItem[]>('cart', [])

const totalPrice = computed(() => orderItems.value.reduce((sum, item) => sum + (item.item.price * item.quantity), 0))

function addItem (item: OrderItem) {
  orderItems.value.push(item)
  toggleOpen(true)
}

function removeItem (item: OrderItem) {
  orderItems.value.splice(orderItems.value.indexOf(item))
}

export default function useCart () {
  return {
    orderItems,
    open,
    totalPrice,
    toggleOpen,
    addItem,
    removeItem,
  }
}
